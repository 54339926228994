
import selfServe from '@/store/modules/selfServe'
import { TripTypeId } from '@/utils/enum'
import { Vue, Component } from 'vue-property-decorator'
import CURadioButton from '@/components/CURadioButton.vue'
import oneWayIcon from '@/assets/images/selfServe/oneWay.svg'
import roundTripIcon from '@/assets/images/selfServe/roundTrip.svg'
import largeEventIcon from '@/assets/images/selfServe/largeEvent.svg'
import { SalesBotTrip } from '@/classes/SalesBotTrip'
import auth from '@/store/modules/auth'

interface TripType {
  id: number
  label: string
  icon: any
  description: string
}

@Component({ components: { CURadioButton } })
export default class SalesBotTripType extends Vue {
  tripTypes: TripType[] = [
    {
      id: TripTypeId.OneWay,
      label: 'One Way',
      icon: oneWayIcon,
      description: 'A one way trip is pretty self-explanatory.',
    },
    {
      id: TripTypeId.RoundTrip,
      label: 'Round Trip',
      icon: roundTripIcon,
      description: 'A round trip always brings you back home.',
    },
    {
      id: TripTypeId.LargeEvent,
      label: 'Large Event',
      icon: largeEventIcon,
      description: 'A large event requires six or more vehicles.',
    },
  ]

  created() {
    if (!this.selectedTripType) {
      this.selectTripType(TripTypeId.RoundTrip)
    }
  }

  mounted() {
    this.trackViewSelfServeStart()
  }

  get selectedTripType(): TripType {
    const selectedTripType = this.tripTypes.find(
      (tripType) => selfServe?.quote?.trips?.[0]?.tripTypeId === tripType.id
    )
    return selectedTripType || null
  }

  get description(): string {
    return this.selectedTripType?.description || ''
  }

  isTripTypeSelected(tripTypeId: number): boolean {
    if (!this.selectedTripType) {
      return false
    }
    return this.selectedTripType.id === tripTypeId
  }

  selectTripType(tripTypeId: number, tripIndex = 0): void {
    selfServe.setTripType({ tripIndex, tripTypeId })
  }

  nextStep(): void {
    const currentTrip: SalesBotTrip =
      selfServe.quote?.trips?.[selfServe.currentTripIndex]
    const isRoundTrip = currentTrip.tripTypeId === TripTypeId.RoundTrip
    const isLessThan3Stops = currentTrip.stops.length < 3
    if (isRoundTrip && isLessThan3Stops) {
      currentTrip.addStop()
      currentTrip.copyAddress(0, currentTrip.stops.length - 1)
    }

    const isOneWay = currentTrip.tripTypeId === TripTypeId.OneWay
    const isMoreThan2Stops = currentTrip.stops.length > 2
    const isLastStopEmpty = !currentTrip.stops[currentTrip.stops.length - 1]
      .address
    if (isOneWay && isMoreThan2Stops && isLastStopEmpty) {
      currentTrip.removeStop(currentTrip.stops.length - 1)
    }
    this.trackTripTypeSubmission()
    selfServe.nextStep()
  }

  trackTripTypeSubmission(): void {
    if (auth.customer != null) {
      this.$ga4Event('sb_trip_type', {
        isElite: !!auth.customer.isElite,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: auth?.customer?.convertedQuoteCount > 0,
        isLoggedIn: true,
      })
    } else {
      this.$ga4Event('sb_trip_type', {
        isElite: false,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: false,
        isLoggedIn: false,
      })
    }
  }

  get buttonText(): string {
    return selfServe.isEditing ? 'Update' : 'Continue'
  }

  trackViewSelfServeStart(): void {
    if (auth.customer != null) {
      this.$ga4Event('sb_view_start', {
        isElite: !!auth.customer.isElite,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: auth?.customer?.convertedQuoteCount > 0,
        isLoggedIn: true,
      })
    } else {
      this.$ga4Event('sb_view_start', {
        isElite: false,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: false,
        isLoggedIn: false,
      })
    }
  }
}
