
import { Vue, Component, Prop } from 'vue-property-decorator'
import auth from '@/store/modules/auth'

const DEFAULT_CHARTERUP_SUPPORT_EMAIL = 'support@charterup.com'

@Component({})
export default class QuoteNotOwned extends Vue {
  @Prop({ type: String, required: true }) wrongEmail!: string
  @Prop({ type: String, required: true }) correctEmail!: string

  DEFAULT_CHARTERUP_SUPPORT_EMAIL = DEFAULT_CHARTERUP_SUPPORT_EMAIL

  auth = auth
}
