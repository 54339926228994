
import {Vue, Component, Prop, Watch } from 'vue-property-decorator'
import router from '@/router'
import { logger } from '@/utils/logger'
import auth from '@/services/auth'
import modules from '@/store/modules'
import customer from '@/services/customer'
import { Customer, CustomerValidatePayload, CustomerValidateResult } from '@/models/dto'
import { RawLocation } from 'vue-router'
import { TranslateResult } from 'vue-i18n'
import AlertBox from '@/components/AlertBox.vue'
import SignUpFormExpired from '@/components/SignUpFormExpired.vue'
import SignUpFormLegalese from '@/components/SignUpFormLegalese.vue'
import { RECEIVE_SMS_QUERY_PARAM } from '@/utils/constants'
import { phoneRaw } from '@/utils/phone'

@Component({
  components: {
    AlertBox,
    SignUpFormExpired,
    SignUpFormLegalese,
  },
})
export default class SignUpFormLegacy extends Vue {
  @Prop({ type: Boolean, required: true }) readonly isValidCustomer: boolean
  @Prop({ type: Object, default: () => {} }) readonly customer: CustomerValidateResult

  firstName = ''
  lastName = ''
  phone = ''
  email = ''
  hash = ''
  password = ''
  passwordHidden = true
  receiveSMSTripUpdatesCheckbox = true
  errorMessage = false
  isSubmitting = false
  asyncPasswordResponse = {
    data: {
      valid: false,
      message: 'Something went wrong'
    }
  }
  shouldValidatePassword = false
  showMainPanel = false

  @Watch('customer', { immediate: true, deep: true })
  onCustomerChange(): void {
    this.populateUserDetails()
    this.autoFocusInput()
  }

  readonly phoneRules: ((v: string) => boolean | string)[] = [
    (v: string): boolean | string =>
      !!v || this.$t('signup.FORM.PHONE.RULES.REQUIRED').toString(),
    (v: string): boolean | string =>
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
      this.$t('signup.FORM.PHONE.RULES.VALID').toString(),
  ]
  readonly firstNameRules: ((v: string) => boolean | string)[] = [
    (v: string): boolean | string =>
      !!v || this.$t('signup.FORM.FIRST_NAME.RULES.REQUIRED').toString(),
  ]
  readonly lastNameRules: ((v: string) => boolean | string)[] = [
    (v: string): boolean | string =>
      !!v || this.$t('signup.FORM.LAST_NAME.RULES.REQUIRED').toString(),
  ]
  readonly emailRules: ((v: string) => boolean | string)[] = [
    (v: string): boolean | string =>
      !!v || this.$t('signup.FORM.EMAIL.RULES.REQUIRED').toString(),
    (v: string): boolean | string =>
      /.+@.+/.test(v) || this.$t('signup.FORM.EMAIL.RULES.VALID').toString(),
  ]

  get isReservationInvite(): boolean {
    return this.$route?.query?.entityType === 'reservation'
  }

  get isCustomerInvite(): boolean {
    return this.$route?.query?.isCustomerInvite === 'true'
  }

  get isIncompleteCustomer(): boolean {
    return this.$route?.query?.isIncompleteCustomer === 'true'
  }

  get quoteHash(): string | null {
    return this.$route?.query?.quoteHash as string
  }

  get userHash(): string | null {
    return this.$route?.query?.userHash as string
  }

  get phoneRaw(): string {
    return phoneRaw(this.phone)
  }

  get hasNoQuoteHash(): boolean {
    return !!this.userHash && !this.quoteHash
  }

  get subHeadingMessage(): TranslateResult {
    if (this.isIncompleteCustomer) {
      return this.$t('signup.SUBHEAD_INCOMPLETE_CUSTOMER')
    }

    if (this.isReservationInvite) {
      return this.$t('signup.SUBHEAD_RESERVATION_INVITE')
    }

    if (this.hasNoQuoteHash) {
      return this.$t('signup.SUBHEAD_USER_HASH_ONLY')
    }

    return this.$t('signup.SUBHEAD')
  }

  get receiveSMSTripUpdates(): boolean {
    return this.isReservationInvite && this.receiveSMSTripUpdatesCheckbox
  }

  created(): void {
    const isAuthenticated = !!modules.auth.isTokenSet
    const isCompleteUser = !this.isIncompleteCustomer
    if (isAuthenticated && isCompleteUser) {
      router.push({ name: 'quotes' })
    }
  }

  autoFocusInput(): void {
    if (!this.isValidCustomer) {
      return
    }

    if (this.isCustomerInvite) {
      this.focusFirstNameInput()
    } else {
      this.focusPasswordInput()
    }
  }

  focusFirstNameInput(): void {
    const firstNameElement = this.$refs.firstName as any
    if (!firstNameElement) {
      return
    }
    firstNameElement.focus()
  }

  focusPasswordInput(): void {
    const passwordElement = this.$refs.password as any
    if (!passwordElement) {
      return
    }
    passwordElement.focus()
  }

  populateUserDetails(): void {
    const { params } = this.$route

    // Route might contain quoteHash or userHash,
    // meaning this is a refresh for a new user signup.
    if (this.quoteHash || this.userHash) {
      const { firstName, lastName, email, phone, hash } = this.customer
      this.firstName = firstName
      this.lastName = lastName
      this.email = email
      this.phone = phone
      this.hash = hash
      this.showMainPanel = true
    } else {
      // Populate details from route params
      const { firstName = '', lastName = '', email = '', phone = '', hash = '' } = params
      this.firstName = firstName
      this.email = email

      if (this.isIncompleteCustomer) {
        // Incomplete customer: populate additional details
        this.lastName = lastName
        this.phone = phone
      } else {
        // Redirect from signup email: populate hash
        this.hash = hash
      }
    }
  }

  async validatePassword(): Promise<void> {
    this.shouldValidatePassword = true
    this.asyncPasswordResponse = await auth.validatePassword(this.password)
  }

  async validateForm(): Promise<boolean> {
    await this.validatePassword()
    const form = this.$refs.form as any
    const formIsValid = await form.validate()
    return formIsValid
  }

  async resetPassword(): Promise<void> {
    await auth.resetPassword({
      hash: this.hash,
      password: this.password,
    })
  }

  async login(): Promise<void> {
    await modules.auth.login({
      email: this.email,
      password: this.password,
    })
  }

  async buildCustomerUpdatePayload(): Promise<Customer> {
    const { data } = await customer.byId(modules.auth.userId)
    const customerPayload = data.customer
    customerPayload.firstName = this.firstName
    customerPayload.lastName = this.lastName
    customerPayload.phone = this.phoneRaw
    return customerPayload
  }

  async updateCustomerSMS(): Promise<void> {
    await customer.updateSMS(modules.auth.userId, {
      phoneNumber: this.phoneRaw,
    })
  }

  async updateCustomerDetails(): Promise<void> {
    try {
      const customerPayload = await this.buildCustomerUpdatePayload()
      const { data } = await customer.edit(customerPayload)
      const { smsConfirmed }  = data.customer || {}

      if (!smsConfirmed && this.receiveSMSTripUpdates) {
        await this.updateCustomerSMS()
      }
    } catch (error) {
      console.error(error)
    }
  }

  redirect(): void {
    const { redirectFrom } = this.$route?.query || {}

    if (!redirectFrom) {
      this.$router.push({ name: 'quote-index' })
      return
    }

    const signupParam = '&fromSignup=true'
    const receiveSMSParam = this.receiveSMSTripUpdates ? `&${RECEIVE_SMS_QUERY_PARAM}=true` : ''
    const to = `${redirectFrom}${signupParam}${receiveSMSParam}` as RawLocation

    this.$router.push(to)
  }

  async submit() {
    if (this.isSubmitting) {
      return
    }
    try {
      const isFormValid = await this.validateForm()
      if (!isFormValid) {
        return
      }

      this.isSubmitting = true

      if (!this.isIncompleteCustomer) {
        await this.resetPassword()
        await this.login()
      }

      if (this.isCustomerInvite) {
        await this.updateCustomerDetails()
      }

      this.redirect()
    } catch (error) {
      logger.error(error)
    } finally {
      this.isSubmitting = false
    }
  }
}
