
import { TripEventType } from '@/models/dto'
import type from '@/services/type'
import { Vue, Component } from 'vue-property-decorator'
import SelfServeSpecialTripRequirementsSelection from '@/components/SelfServeSpecialTripRequirementsSelection.vue'
import { SalesBotTrip } from '@/classes/SalesBotTrip'
import selfServe from '../store/modules/selfServe'
import auth from '../store/modules/auth'
import selfServeService from '@/services/selfServe'

@Component({
  components: {
    SelfServeSpecialTripRequirementsSelection,
  },
})
export default class SelfServeTripDetails extends Vue {
  tripEventTypes: TripEventType[] = []
  submitting = false
  errorMessage = false

  get disabled(): boolean {
    return !(
      this.passengers % 1 === 0 &&
      this.passengers > 0 &&
      this.tripEventTypeId &&
      !this.errorMessage
    )
  }

  get currentTrip(): SalesBotTrip {
    return selfServe.quote?.trips?.[selfServe.currentTripIndex] || null
  }

  get passengers(): number {
    return this.currentTrip?.passengerCount || null
  }

  get tripEventTypeId(): number {
    return this.currentTrip?.tripEventTypeId || null
  }

  get tripName(): string {
    return this.currentTrip?.routeName || ''
  }

  get buttonText(): string {
    return selfServe.isEditing ? 'Update' : 'Continue'
  }

  async mounted(): Promise<void> {
    await this.getTripEventTypes()
  }

  async getTripEventTypes(): Promise<void> {
    const tripEventResponse = await type.tripEvent({})
    this.tripEventTypes = tripEventResponse.data.resultList
  }

  setPassengerCount(passengerCount: number, tripIndex = 0) {
    selfServe.setTripPassengerCount({ tripIndex, passengerCount })
  }

  setTripEventTypeId(tripEventTypeId: number, tripIndex = 0) {
    selfServe.setTripEventTypeId({ tripIndex, tripEventTypeId })
  }

  setTripName(tripName: string, tripIndex = 0) {
    selfServe.setTripName({ tripIndex, tripName })
  }

  async goToNextStep(): Promise<void> {
    if (auth.isAuthorizedUser) {
      await this.submit()
    }
    this.trackTripDetailsSubmission()
    selfServe.nextStep()
  }

  async submit(): Promise<void> {
    try {
      this.submitting = true
      const payload = selfServe.quote.toObject()
      payload.oldQuoteId = selfServe.quoteId
      const result = await selfServeService.create(payload)
      const quotes = result.data?.data
      const selfServeId = quotes?.[0]?.selfServeId

      selfServe.setSelfServeId(selfServeId)
      const quoteDetailsResponse = await selfServeService.bySelfServeIdV2(
        selfServeId
      )
      const quoteDetails = quoteDetailsResponse.data?.data
      selfServe.setQuoteDetails(quoteDetails)
    } catch (err) {
      console.error(err)
    }
    this.submitting = false
  }

  trackTripDetailsSubmission(): void {
    if (auth.customer != null) {
      this.$ga4Event('sb_trip_details', {
        isElite: !!auth.customer.isElite,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: auth?.customer?.convertedQuoteCount > 0,
        isLoggedIn: true,
      })
    } else {
      this.$ga4Event('sb_trip_details', {
        isElite: false,
        isAuth0: this.$auth0.isInitialized,
        hasBookedBefore: false,
        isLoggedIn: false,
      })
    }
  }
}
