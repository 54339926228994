import Vue from 'vue'
import RouteConfigWithMeta from '@/models/RouteConfig'
import auth from '@/store/modules/auth'
import authService from '@/services/auth'
import selfServe from '@/store/modules/selfServe'
import { SplitFeatureFlag } from '@/utils/enum'
import { CustomerValidatePayload, CustomerValidateResult } from '@/models/dto'
import { buildRedirectLoginOptions, goToAuth0SignUpViaRedirect, shouldUseAuth0Login } from '@/utils/auth'
import { RawLocation } from 'vue-router'

const preauth: RouteConfigWithMeta = {
  path: '',
  name: 'preauth',
  meta: { requiresAuth: false },
  component: () => import('@/views/Home.vue'),
  children: [
    {
      path: '/login',
      name: 'login',
      meta: { requiresAuth: false },
      beforeEnter: async (to, _from, next) => {
        const isAuthenticated = !!auth.isTokenSet
        const redirectFrom = to.query.redirectFrom as string

        if (isAuthenticated) {
          const redirectTo = (redirectFrom || { name: 'quote-index' }) as RawLocation
          return next(redirectTo)
        }

        const isAuth0Enabled = await shouldUseAuth0Login(to)

        if (isAuth0Enabled) {
          const auth0 = Vue.prototype.$auth0
          const appState = { routeName: 'home', redirectFrom }
          const redirectOptions = buildRedirectLoginOptions(appState)
          const isAuth0Loaded = !!auth0
          const isAuth0Initialized = isAuth0Loaded && auth0.isInitialized

          if (isAuth0Loaded) {
            if (!isAuth0Initialized) {
              await auth0.init()
            }
            return auth0.loginWithRedirect(redirectOptions)
          }
        }

        next()
      },
      component: () =>
        import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    },
    {
      path: '/signup',
      name: 'signup',
      meta: { requiresAuth: false },
      props: true,
      beforeEnter: async (to, _from, next) => {
        let isValidCustomer = true as any
        let customer = null
        const isAuthenticated = !!auth.isTokenSet
        const quoteHash = to.query.quoteHash as string
        const userHash = to.query.userHash as string

        try {
          customer = await validateCustomer(quoteHash, userHash)
        } catch (e) {
          isValidCustomer = false
        }

        const email = quoteHash || userHash ? customer?.email : to.params.email
        const isAuth0FlagEnabled = await Vue.prototype.$split.isFeatureEnabled(SplitFeatureFlag.Auth0SignIn)
        const isAuth0Initialized = !!Vue.prototype.$auth0?.isInitialized
        const isAuth0Enabled = isAuth0FlagEnabled || isAuth0Initialized

        to.params.isValidCustomer = isValidCustomer
        to.params.customer = customer

        if (isAuth0Enabled) {
          if (isAuthenticated) {
            return next({ name: 'quote-index' })
          }
          await goToAuth0SignUpViaRedirect(to.query, userHash, email)
          return
        }

        return next()
      },
      component: () =>
        import(/* webpackChunkName: "signup" */ '@/views/SignUp.vue'),
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      meta: { requiresAuth: false },
      beforeEnter: (_to, _from, next) => {
        if (auth.isTokenSet) {
          next({ name: 'home' })
        } else {
          next()
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "forgot-password" */ '@/views/ForgotPassword.vue'
        ),
    },
    {
      path: '/reset-password/:id',
      name: 'reset-password',
      meta: { requiresAuth: false },
      beforeEnter: async (_to, _from, next) => {
        if (auth.isTokenSet) {
          return next({ name: 'home' })
        }

        const isAuth0FlagEnabled = await Vue.prototype.$split.isFeatureEnabled(SplitFeatureFlag.Auth0SignIn)
        const isAuth0Initialized = !!Vue.prototype.$auth0?.isInitialized
        const isAuth0Enabled = isAuth0FlagEnabled || isAuth0Initialized

        if (isAuth0Enabled) {
          await Vue.prototype.$auth0.loginWithRedirect()
          return
        }

        return next()
      },
      component: () =>
        import(
          /* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'
        ),
    },
    {
      path: '/selfServe/:quoteId?',
      name: 'self-serve',
      meta: { requiresAuth: false },
      beforeEnter: (_to, _from, next) => {
        selfServe.initialize()
        next()
      },
      component: () =>
        import(
          /* webpackChunkName: "self-serve" */ '@/views/SelfServe.vue'
        ),
    },
    {
      path: '/cancellation/:hash',
      name: 'cancellation',
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "cancellation" */ '@/views/Cancellation.vue'
        ),
    },
    {
      path: '/guest-checkout/:hash/confirmation',
      name: 'guest-checkout.confirmation',
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "checkout-confirmation" */ '@/views/GuestCheckoutConfirmation.vue'
        ),
    },
    {
      path: '/guest-checkout/:hash',
      name: 'guest-checkout',
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "guest-checkout" */ '@/views/GuestCheckout.vue'
        ),
    },
    {
      path: '/*',
      name: 'not-found',
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "not-found" */ '@/views/NotFound.vue'
        ),
    },
  ],
}

export default preauth


const buildCustomerValidationPayload = (quoteHash: string | undefined, userHash: string | undefined): CustomerValidatePayload => {
  const validateUserPayload: CustomerValidatePayload = { verifyCustomerDetails: true }
    if (quoteHash) {
      validateUserPayload.quoteHash = quoteHash
    }

    if (userHash) {
      validateUserPayload.userHash = userHash
    }

    return validateUserPayload
}

const validateCustomer = async (quoteHash: string, userHash: string): Promise<CustomerValidateResult> => {
  const validateUserPayload = buildCustomerValidationPayload(quoteHash, userHash)
  const { data } = await authService.validateCustomer(validateUserPayload)
  return data
}
