
import { TranslateResult } from 'vue-i18n'
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import { CancellationRefundPercentage } from '@/utils/enum'
import InfoModal from '@/components/InfoModal.vue'
import { refundPolicyModalDetail } from '@/utils/infoModals'
import { RefundPolicyEntry } from '@/models/dto'
import { formattedCancellationPolicyDate } from '@/utils/cancellation'
import { InfoModalDetail } from '@/models/InfoModalDetail'

@Component({ components: { InfoModal } })
export default class ReservationCancellationPolicy extends Vue {
  @Prop({ type: String, required: true }) startDate!: string
  @Prop({ type: Boolean, required: false, default: false })
  readonly fullWidth!: boolean
  @Prop({ type: Number, required: true })
  refundPolicyPercent!: number
  @Prop({ type: String, required: true })
  refundPolicyPercentValidUntilTime!: string
  @Prop({ type: Array, required: true })
  refundPolicy!: RefundPolicyEntry[]
  @Prop({ type: Boolean, default: false }) readonly isElite: boolean
  @Prop({ type: Boolean, default: false }) readonly isSelfServe: boolean

  @Inject({ from: 'isInCheckout', default: false })
  readonly isInCheckout: boolean
  @Inject({ from: 'isInGuestCheckout', default: false })
  readonly isInGuestCheckout: boolean
  @Inject({ from: 'isInReservationCancel', default: false })
  readonly isInReservationCancel: boolean

  isOpen = false

  get refundPolicyModalDetail(): InfoModalDetail {
    return refundPolicyModalDetail(this.refundPolicy)
  }

  get cancellationPolicyCopy(): TranslateResult {
    if (this.refundPolicyPercent === CancellationRefundPercentage.Full) {
      // Full refund
      return this.$t('refundPolicy.CHECKOUT_100_PERCENT_REFUND', {
        date: formattedCancellationPolicyDate(
          this.refundPolicyPercentValidUntilTime
        ),
      })
    }
    if (this.refundPolicyPercent === CancellationRefundPercentage.None) {
      // No refund
      return this.$t('refundPolicy.CHECKOUT_NO_REFUND')
    }
    return this.$t('refundPolicy.CHECKOUT_PARTIAL_PERCENT_REFUND', {
      percent: this.refundPolicyPercent * 100,
      date: formattedCancellationPolicyDate(
        this.refundPolicyPercentValidUntilTime
      ),
    })
  }

  get threeDaysBeforeFirstTrip(): string {
    if (!this.startDate) {
      return null
    }
    return this.$dayjs(this.startDate).subtract(3, 'day').format('MMMM D')
  }

  get thirtyDaysBeforeFirstTrip(): string {
    if (!this.startDate) {
      return null
    }
    return this.$dayjs(this.startDate).subtract(30, 'day').format('MMMM D')
  }

  get page(): string {
    if (this.isInCheckout) {
      return 'Checkout'
    }
    if (this.isInGuestCheckout) {
      return 'Guest Checkout'
    }
    if (this.isInReservationCancel) {
      return 'Reservation Cancel'
    }
    return 'Other'
  }

  trackOpenRefundPolicy(): void {
    this.$ga4Event('open_refund_policy', {
      page: this.page,
      isSelfServe: this.isSelfServe,
      isElite: this.isElite,
      isAuth0: this.$auth0.isInitialized
    })
  }
}
